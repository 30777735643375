
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/latitude/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: relative;
  width: 100%;

  .icon {
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -12px;
  }
}
