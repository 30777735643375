@import url('https://fonts.googleapis.com/css?family=Montserrat:300,500,600,700|Roboto&display=swap');
$c-primary: #0046aa;
$c-secandary: #63b8ff;
$dots: false;
$version: 3.1;

$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;

$heading-color-overrided: #0046aa;
$body-color-overrided: #333333;

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-BoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$project-font-path}Montserrat-SemiBold.ttf');
  font-weight: 700;
  font-style: normal;
}

$base-font-family: 'Roboto', sans-serif;
$font-family-heading: 'Montserrat', sans-serif;

button[class*='Button_style_secandary'] {
  border-radius: 4px !important;
}
