
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/latitude/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  .content {
    margin-bottom: 120px;
  }
  ol[type='a'] {
    padding-left: 20px;
    li {
      list-style: none;
      counter-increment: lowerAlpha;
      &:before {
        content: '(' counter(lowerAlpha, lower-alpha) ') ';
      }
    }
  }
  h3 {
    font-weight: 600;
  }

  a {
    word-wrap: break-word;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 25px;
    margin-top: 20px !important;
  }
}
