
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/latitude/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: relative;

  .title {
    margin-bottom: 25px;
  }

  .atAddress {
    margin: 10px 0 30px;
    max-width: 270px;
  }

  .input {
    &.halfWith {
      max-width: 50%;
      margin-right: 15px !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .addresses {
    display: block;
    background: #fff;
    border: 1px solid #ccc;
    padding: 0;
    position: absolute;
    width: calc(100% - 30px);
    box-shadow: -1px 1px 3px #eee;
    max-height: 200px;
    overflow: auto;
    margin-top: -15px;
    z-index: 2;

    li {
      display: block;
      padding: 13px;
      border-bottom: 1px solid #ddd;
      font-size: 12px;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .countries {
    display: block;
    background: #fff;
    border: 1px solid #ccc;
    padding: 0;
    position: absolute;
    width: calc(100% - 30px);
    box-shadow: -1px 1px 3px #eee;
    max-height: 200px;
    overflow: auto;
    margin-top: -15px;
    z-index: 2;

    li {
      display: block;
      padding: 13px;
      border-bottom: 1px solid #ddd;
      font-size: 12px;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
