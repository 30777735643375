
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/latitude/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 0 0 5px 0;
  text-align: center;
  max-width: 545px;
  margin: auto;

  .heading {
    font-family: var(--heading-font-family) !important;
    margin-bottom: 25px;
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 31px;
    letter-spacing: -0.5px;

    small {
      font-family: var(--base-font-family) !important;
      font-weight: 400;
      display: block;
      margin-top: 12px;
    }
  }
}
.link {
  display: block;
  text-align: center;
  position: relative;
  top: -4px;
  margin-bottom: 32px;
  a {
    color: var(--secondary);
    font-size: 13px;
    line-height: 19px;
    text-decoration: none;
    background-color: lighten($c-secandary, 46%);
    border-radius: 4px;
    padding: 5px 10px;
    svg {
      position: relative;
      top: 3px;
      g {
        fill: var(--secondary);
      }
    }
  }
}
@media (max-height: 510px) {
  .wrapper {
    display: block;
    position: relative;
    padding: 0;
    .blocks {
      margin-top: 25px;
    }
  }
}
