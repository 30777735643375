
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/latitude/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  table {
    width: 90%;
    margin: 2rem auto;
    vertical-align: middle;
    //border: 1px solid #ccc;
    //border-bottom: none;
    td {
      vertical-align: middle;
      font-family: var(--base-font-family) !important;
      padding: 15px;
      border: 1px solid #ccc;
      &:first-child {
        font-weight: 600;
        background-color: #ccc;
      }
    }
  }
}
